import * as Yup from "yup";

export const LoginSchema = Yup.object(
    {
        Login: new Yup.ObjectSchema({
            Email: Yup
                .string()
                .email("email is valid")
                .required("email is required"),
            Password: Yup
                .string()
                .required("password is required"),
        })
    }
).required();

export interface LoginValidationModel {
    Login: {
        Email: string,
        Password: string,
    }
}