import React from "react";
import "./index.scss";
import { DefaultButton } from '@fluentui/react/lib/Button';
import { Link } from "gatsby";
import LoginForm from "../components/LoginForm";
import NewsletterForm from "../components/NewsletterForm";
import Footer from "../components/Footer";


// In Gatsby, Page == Component
export default function Login() {

    return (
        <main className="container">

            <title>Login Page</title>
            <div className="top-container login-page">
                <header>
                    <nav>
                        <div className="logo-container">
                            <Link to="/">
                                <image className="logo"/>
                            </Link>    
                        </div>
                        <div className="menuItems">
                        <Link to="/" className="navButton menuItem-1">Contact</Link>
                        <Link to="/login" className="navButton menuItem-2">Partner Login</Link>
                    </div>
                    </nav>
                </header>
                <section id="inquiryForm" className="bottomNoGraphic">
                    <div className="webform inquiry-form login">
                        <LoginForm />
                    </div>
                </section>

                <Footer />
            </div>  

        </main>
    )
}



