import React from "react";
import { TextField } from '@fluentui/react/lib/TextField';
import { yupResolver } from '@hookform/resolvers/yup';
import { DefaultButton } from '@fluentui/react/lib/Button';
import { LoginSchema, LoginValidationModel } from "../helpers/LoginFormModel";
import { useForm } from "react-hook-form";

const onError = (data: any) => {
    console.log(data);
};

export default function LoginForm() {

    const { register, formState: { errors }, handleSubmit } = useForm<LoginValidationModel>({ resolver: yupResolver(LoginSchema)});

    const onSubmit = (data: any) => {
        console.log(data);
    }

    return (
        <React.Fragment>
            <form onSubmit={handleSubmit(onSubmit, onError)} method="POST">
                <h2 className="webform-header">Login</h2>
                <TextField
                    id="txtName"
                    placeholder="Email"
                    {...register("Login.Email")}
                />
                <TextField
                    id="txtPassword"
                    type="password"
                    placeholder="Password"
                    {...register("Login.Password")}
                />
                <DefaultButton
                    text="Login"
                    type="submit"
                    onSubmit={(event) => event.preventDefault()}
                    allowDisabledFocus
                />
            </form>
        </React.Fragment>
    );
}